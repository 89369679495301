<template>
  <div class="hotmom-order">
    <div class="banner">
      <img :src="sinfo.pic.picUrl" alt />
    </div>
    <div class="container">
      <div class="title-en">New Customized Products</div>
      <div class="title-zh">甄选系列</div>
      <div class="filter-content">
        <div :class="{filter:true,'pop-show':!!activeFilter}" @click.stop>
          <div class="filter-select">
            <div
              class="filter-item"
              v-for="item in filterList"
              :key="item.id"
              @click="filterClick(item)" 
            >
              <span>{{item.name}}</span>
              <i class="el-icon-arrow-down"></i>
            </div>
          </div>
          <!--饰品分类-->
          <div class="filter-pop" v-show="activeFilter==='type'">
            <div
              :class="{'filter-pop-item':true,actived:seachData.type == item.id}"
              v-for="item in typeList"
              :key="item.id"
              @click="tagClick('type', item.id)"
            >{{ item.name }}</div>
          </div>
          <!--主石重量-->
          <div class="filter-pop" v-show="activeFilter==='carat'">
            <div
              :class="{'filter-pop-item':true,actived:seachData.carat === item.ct}"
              v-for="item in weight"
              :key="item.id"
              @click="tagClick('carat', item.ct)"
            >{{ item.ct }}ct</div>
          </div>
          <!--主石形状-->
          <div class="filter-pop" v-show="activeFilter==='dtype'">
            <div
              :class="{'filter-pop-item':true,actived:seachData.dtype === item.id}"
              v-for="item in shape"
              :key="item.id"
              @click="tagClick('dtype', item.id)"
            >{{ item.name }}</div>
          </div>
          <!--价格筛选-->
          <div class="filter-pop" v-show="activeFilter==='price'">
            <div
              :class="{'filter-pop-item':true,actived:seachData.price === index}"
              v-for="(item,index) in price"
              :key="item.id"
              @click="tagClick('price', index)"
            >{{ item.price }}</div>

            <div class="price-range">
              <input type="text" v-model="minprice" placeholder="人民币" />
              <span class="divide">-</span>
              <input type="text" v-model="maxprice"  placeholder="人民币" />
              <el-button class="btn" :disabled="!(minprice*1<maxprice*1)" @click="roleSearch()">确定</el-button>
            </div>
          </div>
        </div>
      </div>



      <div class="ware-list clearfix">
        <div
          :class="{'ware-item':true,'no-margin':!(index%3)}"
          v-for="(item, index) in wareList"
          :key="item.id"
          @click="uplars(item.id)"
        >
          <div class="img">
            <img :src="item.pic.picUrl" alt  class="img-item"/>
          </div>

            <!--收藏-->
             <img
              :src="item.iscare == 1 ? lovered : loveno"
              alt
              class="collect"
              @click.stop="
                collect(item,index);
              "
            />



          <div class="title">{{item.title}}</div>
          <div class="borders"></div>
          <div class="desc">{{item.desc}}</div>
           <div class="car" @click.stop="addcar(item.id,$event)">
            <span class="price">{{item.minprice|toRMB}}</span>
            <button class="addcar">添加至购物车</button>
          </div>
        </div>
      </div>
      <div class="pagenation">
        <el-pagination
          layout="prev, pager, next"
          :total="total"
          :page-size="pagenation.size"
          hide-on-single-page
          @current-change="pageChange"
        ></el-pagination>
      </div>

      <div v-if="wareList == null" class="zanwu">暂无商品</div>

      <div class="serise-title">伊莉丝甄选系列</div>
      <div class="serise-sub-title">伊莉丝甄选系列的灵感源自于对非凡造型的传承、精湛工艺及对美学的不懈追求</div>
      <div class="serise-warelist">
        <div class="img" v-for="(item,index) in seriseWarelist" :key="index">
          <img :src="item.picUrl" alt />
        </div>
      </div>
      <div class="bottom">
        <div class="left">
          <div class="title">{{sinfo.zxtitle}}</div>
          <div class="point">.....</div>
          <p>{{sinfo.zxdes}}</p>
        </div>
        <div class="right">
          <img :src="sinfo.spic.picUrl" alt />
        </div>
    </div>
    </div>
  </div>
</template>

<script>
// import { GET_DIAMON_PARAM } from "../../service/other";
import {
  prolist as GET_THEMELIST,
  collect as POST_COLLECT,
  series as GET_SERIES,
  add as POST_ADD
} from "../../service/api";

export default {
  name: "HotMom",
  components: {},
  data() {
    return {
      activeFilter: "",
      seachData: { type: "", carat: "", dtype: "", price: "" },
      filterList: [
        { id: "type", name: "饰品类别" },
        { id: "carat", name: "主石重量" },
        { id: "dtype", name: "主石形状" },
        { id: "price", name: "价格筛选" }
      ],
      typeList: [
        { id: 0, name: "全部" },
        { id: 3, name: "项链" },
        { id: 2, name: "戒指" },
        { id: 1, name: "手链" },
        { id: 4, name: "耳饰" },
        { id: "diamondorder", name: "裸钻定制" }
      ],
      weight: [
        { id: 1, ct: 0.3 },
        { id: 2, ct: 0.5 },
        { id: 3, ct: 0.8 },
        { id: 4, ct: 1 },
        { id: 5, ct: 2 }
      ], //圆形(0) 公主方形(1) 祖母绿形(2) 雷地恩形(3) 枕垫形(4) 梨形(5) 椭圆形(6) 心形(7)
      shape: [
        { id: -1, name: "全部" },
        { id: 0, name: "圆形" },
        { id: 1, name: "公主方形" },
        { id: 2, name: "祖母绿形" },
        { id: 3, name: "雷迪恩形" },
        { id: 4, name: "垫形" },
        { id: 5, name: "梨形" },
        { id: 6, name: "阿斯切形" },
        { id: 8, name: "心形" }
      ],
      price: [
        { id: 1, price: "5000以下" },
        { id: 2, price: "5001-10000" },
        { id: 3, price: "10001-20000" },
        { id: 4, price: "20001-40000" },
        { id: 5, price: "40000以上" }
      ],
       loveno: require("@/assets/images/loveccc.png"),
      lovered: require("@/assets/images/lovered.png"),
      wareList: [],
      seriseWarelist: [],
      pagenation: {
        start: 0,
        size: 12
      },
      total: 0,
      sinfo: { pic: {}, spic: {} },
      ids:'',
      maxprice:"",
      minprice:'',
    };
  },
  computed: {
    seriesId() {
      return this.$route.query.seriesid || "";
    }
  },
  watch: {
    seriesId: {
      immediate: true,
      handler(newVal) {
        if (newVal !== "") {
          this.getSeries();
        }
      }
    },
  },
  created() {
    this.bind();
    this.getSeries();
  },
  beforeDestroy() {
    document.body.removeEventListener("click", this.bodyClick);
  },
  methods: {
    input(){

    },
    
    ad(){
      this.activeFilter = ''
    },
     addcar(e, event) {
      // console.log(e);
      if (this.$store.getters["user/getToken"]) {
        let user = this.$store.getters["user/getuserdata"];
        POST_ADD({
          uid: user.uid,
          pnum: 1,
          skuid: 0,
          sizeid:0,
          productid: e
        }).then(res => {
          // console.log(res);
          if (res.status == 400) { 
            this.$notify.error({
              title: "错误",
              message: res.message,
              offset: 100
            });
          } else if (res.status == 402) {
            this.$notify.error({
              title: "错误",
              message: res.message,
              offset: 100 
            });
          } else if (res.status == 200) {
            //购物车动画

            var offset = $(".spw").offset();
            var addcar = $(this);
            var img = $(event.target)
              .parent()
              .parent()
              .find('.img')
              .find(".img-item")
              .attr("src");
              // console.log(img)
            var flyer = $(
              '<img class="u-flyer" src="' + img + '" style="z-index:99999 ;border-radius: 50%;width:50px;height:50px">'
            );
            flyer.fly({
              start: {
                left: event.pageX, //开始位置（必填）#fly元素会被设置成position: fixed
                top: event.pageY //开始位置（必填）
              },
              end: {
                left: offset.left + 10, //结束位置（必填）
                top: offset.top + 10, //结束位置（必填）
                width: 0, //结束时宽度
                height: 0 //结束时高度
              },
              onEnd: function() {
                //结束回调
                $("#msg")
                  .show()
                  .animate({ width: "250px" }, 200)
                  .fadeOut(1000); //提示信息
                addcar
                  .css("cursor", "default")
                  .removeClass("orange")
                  .unbind("click");
              }
            });

            this.$store.commit("user/SETCAR", res.attachment.total);
            this.$notify({
              title: "成功",
              message: "您已成功添加此商品",
              type: "success",
              offset: 100
            });



          } else if (res.status == 204) {
            // this.$notify.error({
            //   title: "错误",
            //   message: res.message,
            //   offset: 100  
            // });
            let loading = this.$loading({
              lock: true,
              text: res.message,
              spinner: "el-icon-loading",
              background: "rgba(0, 0, 0, 0.7)"
            });
            setTimeout(() => {
              loading.close();
              this.$router.push({
                path: "/productInfo.html",
                query: {
                  id: e
                }
              });
            }, 300);
          }
        });
      } else {
        this.$notify.error({
          title: "未登录",
          message: "您还未登录，赶紧去登陆吧",
          offset: 100
        });
        let loading = this.$loading({
          lock: true,
          text: "您还未登录，正在跳转登陆页面...",
          spinner: "el-icon-loading",
          background: "rgba(0, 0, 0, 0.7)"
        });
        setTimeout(() => {
          loading.close();
          this.$router.push({
            path: "/createLogin.html?url=/productList.html?seriesid=" + this.$route.query.id
          });
        }, 300);
      }
    },
    // 收藏
    collect(item, index) {
      console.log(item,index)
      if (this.$store.getters["user/getToken"]) {
        let type = item.iscare == 1 ? 2 : 1;
        POST_COLLECT({
          type,
          productid: item.id
        }).then(() => {
          item.iscare = type;
          if(item.iscare == 2){
              this.getSeries()
               this.$notify({
                title: "成功",
                message: "您已取消收藏此商品",
                type: "success",
                offset: 100
            });
          }else{
            this.getSeries()
            this.$notify({
              title: "成功",
              message: "您已成功收藏此商品",
              type: "success",
              offset: 100
            });
          }
        });
      } else {
        this.loading();
        this.$router.push({
          path: "/createLogin.html?url=/production.html?ids=" + this.ids
        });
      }
    },

    
    uplars(id){
         let { href } = this.$router.resolve({
          path: "/productInfo.html",
           query: {
          id: id
        }
      });
      window.open(href, '_blank');
    },
    bind() {
      document.body.addEventListener("click", this.bodyClick);
    },
    getSeries() {
      GET_THEMELIST({ seriesid: this.seriesId, start: this.pagenation.start, size: this.pagenation.size}).then(
        res => {
              this.sinfo = res.attachment.sinfo;
              this.seriseWarelist = res.attachment.sinfo.zxpics;
              this.wareList = res.attachment.datas;
               console.log(this.wareList)
        }
      );
    },
    getWareList() {
      GET_THEMELIST({themeid: this.seriesId, ...this.seachData, ...this.pagenation }).then(res => {
        if (res.status === 200) {
          this.total = res.attachment.total;
          this.wareList = res.attachment.datas;
        }
      });
    },
    bodyClick() {
      this.activeFilter = "";
    },
    // getDiamonPramas() { 
    //   GET_DIAMON_PARAM().then(res => {
    //     if (res.status === 200) {
    //       console.log(res);
    //     }
    //   });
    // },
    filterClick(item) {
      if(this.activeFilter ==item.id ){
        this.activeFilter = ''
      }else{
        this.activeFilter = item.id
      }
      
    },
    tagClick(types, id) {
      this.ad()
      if (id === "diamondorder") {
        return this.orderDimond();
      }
      console.log(types,id)
      if(types == 'type' && id == 0){
        this.seachData = { type: "", carat: "", dtype: "", price: "" }
      }

      if(types == 'price'){
           let object = this.price[id].price
        if(id == 0 || id == 4){
            if(id == 0){
              this.seachData.minprice = 0
              this.seachData.maxprice = 5000
            }
            if(id == 4){
              this.seachData.minprice = 40000
              this.seachData.maxprice = 99999999
            }
        }else{
          let a = object.split('-')
          this.seachData.minprice = parseFloat(a[0])
          this.seachData.maxprice = parseFloat(a[1])
        }

      }
      let val = this.seachData[types];
      this.seachData[types] = val === id ? "" : id;
      this.getWareList();
    },

    roleSearch(){
         this.seachData.minprice = this.minprice
          this.seachData.maxprice = this.maxprice
      this.getWareList();
    },
    orderDimond() {
      let { href } = this.$router.resolve({
          path: "/diamondorder",
        });
      window.open(href, '_blank');
    },
    pageChange(val) {
       document.getElementById("sapp").scrollTop =
        document.getElementsByClassName("title-en")[0].offsetTop -
        document.getElementsByClassName("tab-list")[0].offsetHeight;
      this.pagenation.start = this.pagenation.size * (val - 1);
      this.getWareList();
    }
  }
};
</script>
<style lang="less" scoped>
.zanwu{
  width: 100%;
  height: 500px;
  line-height: 500px;
  text-align: center;
  font-size: 50px;
  font-weight: 500;
}
 .car {
    width: 100%;
    height: 42px;
    margin-top: 1.9vw;
    display: flex;
    justify-content: space-around;
    background: #333333;
    z-index: 9999;
    display: flex;
    justify-content: space-between;
    align-items: center;
    &:hover {
      background: #921d22;
      cursor: pointer;
      .addcar {
        background: #921d22;
      }
    }
    .price {
      font-size: 0.8vw;
      padding-left: 0.6vw;
      color: #fff;
    }
    .addcar {
      outline: none;
      border: none;
      height: 100%;
      font-size: 0.6vw;
      background: #333333;
      color: #fff;
      cursor: pointer;
    }
  }
 .borders {
      width: 2vw;
      height: 1px;
      border-bottom: 1px solid red;
      margin: 2px auto;/* no */
    }
  .collect {
    width: 20px;/* no */
    height: 20px;/* no */
    position: absolute;
    right: 1vw;/* no */
    top: 1vw;/* no */
  }
.hotmom-order {
  .banner {
    width: 100%;
     height: 656px;
    margin: 0 auto 140px;
    overflow: hidden;
    img {
      width: 100%;
       height: 100%;
       object-fit: cover;
    }
  }
  .container {
    width: 1320px;
    margin: 0 auto 200px;
    .title-en {
      font-size: 40px;
      color: #d2d2d2;
      line-height: 1;
      text-align: center;
      margin-bottom: 30px;
    }
    .title-zh {
      font-size: 44px;
      color: #666;
      line-height: 1;
      margin-bottom: 90px;
      text-align: center;
    }
    .filter.pop-show {
      box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
    }
    .filter-content {
      position: relative;
      width: 100%;
      height: 80px;
      z-index: 10;
    }
    .filter {
      width: 100%;
      color: #000;
      position: absolute;
      top: 0;
      left: 0;
      .filter-item {
        cursor: pointer;
        span {
          padding-right: 40px;
        }
      }
      .filter-select {
        width: 100%;
        display: flex;
        justify-content: space-between;
        border-bottom: 1px solid #cfcfcf; /*no*/
        line-height: 80px;
        padding: 0 70px;
        font-size: 18px;
      }
      .filter-pop {
        // position: absolute;
        width: 100%;
        left: 0;
        top: 80px;
        padding: 30px 0 10px;
        display: flex;
        flex-wrap: wrap;
        background: #fff;
        &:nth-child(3){
          div{
            width: 20%;
          }
        }
        
        > div {
          font-size: 18px;
          width: 20%;
          height: 40px;
          line-height: 40px;
          text-align: center;
          margin-bottom: 20px;
        }
        .price-range {
          width: 100%;
          text-align: left;
          padding-left: 70px;
          input {
            width: 250px;
            height: 40px;
            border: none;
            outline: none;
            background: #f5f5f5;
            padding: 0 18px;
            font-size: 16px;
          }
          .divide {
            padding: 0 12px;
          }
          .btn {
            padding: 0 34px;
            background: #921d22;
            color: #fff;
            margin-left: 54px;
            display: inline-block;
            line-height: 40px;
            font-size: 16px;
          }
        }
        .filter-pop-item.actived {
          color: #921d22;
        }
        .filter-pop-item {
          cursor: pointer;
        }
      }
    }
    .ware-list {
      // overflow: hidden;
      // display: flex;
      // flex-wrap: wrap;
      .ware-item.no-margin {
        margin-left: 0;
      }
      .ware-item {
        width: 396px;
        height: 600px;
        // flex: 1;
        float: left;
        margin-left: 66px;
        margin-top: 60px;
        text-align: center;
        position: relative;
         &:hover{
              cursor: pointer;
            }
        .img {
          width: 100%;
          height: 354px;
          overflow: hidden;
          box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
          img {
            width: 100%;
            height: 100%;
            display: block;
            object-fit: cover;
          }
        }
        .title {
          line-height: 60px;
          font-size: 22px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
        .desc {
          line-height: 40px;
          font-size: 17px;
          height: 40px;
          padding: 0 40px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
        .price {
          font-size: 14px;
        }
      }
    }
    .pagenation {
      display: flex;
      justify-content: center;
      padding: 50px 0;
    }
    .serise-title {
      margin-top: 100px;
      margin-bottom: 14px;
      text-align: center;
      color: #666;
      font-size: 44px;
    }
    .serise-sub-title {
      line-height: 84px;
      text-align: center;
      color: #000;
      font-size: 22px;
    }
    .serise-warelist {
      display: flex;
      justify-content: center;
      .img.first-child {
        margin-left: 0;
      }
      .img {
        width: 209px;
        height: 209px;
        margin-left: 30px;
        overflow: hidden;
        img {
          width: 100%;
          height: 100%;
          display: block;
          object-fit: cover;
          &:hover{
            cursor: pointer;
          }
        }
      }
    }
    .bottom {
      margin: 100px 0 200px;
      display: flex;
      justify-content: space-between;
      height: 480px;
      .left {
        width: 487px;
        .title {
          font-size: 44px;
          color: #666;
        }
        .point {
          font-size: 60px;
          line-height: 20px;
          margin-bottom: 50px;
          color: #999;
        }
        p {
          margin-bottom: 32px;
          line-height: 32px;
        }
      }
      .right {
        width: 758px;
        height: 100%;
        img {
           width: 100%;
          height: 100%;
          display: block;
          object-fit: cover;
        }
      }
    }
  }
}
</style>